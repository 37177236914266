.normalLoginForm {
    max-width: 320px;
    width: 80%;
    min-width: 320px;
}

.normalLoginForm > h1 {
}

.ant-input-affix-wrapper {
    border-radius: 15px;
}

.hrText {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: 0.5;
}
.hrText:before {
    content: '';
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
}
.hrText:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 0.5em;
    line-height: 1.5em;
    color: #818078;
    background-color: rgb(240, 242, 245);
}


.outerLoginContainer {
    display: table;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.middleLoginContainer {
    vertical-align: middle;
    display: table-cell;
}
.innerLoginContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}
