.cyclesList {
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
}

.cyclesList > li {
    list-style: none;
    display: flex;
    width: 100%;
    justify-content: center;
}