.app {
    text-align: center;
    min-height: 100vh;
}

.layout {
    display: flex;
    min-height: 100vh;
}

.footer {
    text-align: center;
}